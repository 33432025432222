.Company {

}

.Company .TitleContainer {
    padding-top: 5px;
    padding-bottom: 15px;
    border-bottom: 1px solid #eeeeff;
}

.Company h2 {
    display: inline;
}

.Company .EditAction {
    display: inline-block;
    cursor: pointer;
    font-size: small;
    color: #1976D2;
}

.Company .Content {
    margin: 20px 0;
}

.Company .SubTitle {
    font-weight: normal;
    font-size: 18px;
    display: block;
    padding: 10px 0;
}

.Company .FirstRow {
    border-top: 1px solid #eeeeff;
}

.Company .FormRow {
    margin: 0;
    padding: 7px 15px;
}

.Company .WithBottomBorder {
    border-bottom: 1px solid #eeeeff
}

.Company .ActionButtons {
    text-align: center;
}

.Company .ActionButtons Button {
    margin: 20px 10px 0;
}