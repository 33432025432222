.Offices {

}

.Offices .TitleContainer {
    padding-top: 5px;
    padding-bottom: 15px;
    border-bottom: 1px solid #eeeeff;
}

.Offices .SubTitle {
    display: flex;
    flex-direction: row;
    margin: 0 0 10px;
    border-bottom: 1px dashed #eeeeff;
}

.Offices h3 {
    display: block;
    margin-right: 5px;
}

.Offices .EditAction {
    cursor: pointer;
    font-size: small;
    padding-top: 3px;
    color: #1976D2;
}

.Offices .ListRows {
    background-color: #fafafa;
}

.Offices .Content {
    margin: 20px 0;
}

.Offices .FirstRow {
    border-top: 1px solid #eeeeff;
}

.Offices .FormRow {
    margin: 0;
    padding: 7px 15px;
}

.Offices .ValueRow {
    padding: 4px 11px;
}

.Offices .ValueField {
    font-weight: 400;
    padding: 5px 0;
    color: #000;
}

.Offices .WithBottomBorder {
    border-bottom: 1px solid #eeeeff
}