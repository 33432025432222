.LoginForm {
    max-width: 350px;
}

.LoginFormContainer {
    display: table;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.MiddleWrapper {
    display: table-cell;
    vertical-align: middle;
}

.LoginFormWrapper {
    margin-left: auto;
    margin-right: auto;
    width: 400px;
    border: 1px solid #eef;
    padding: 30px;
}

.LoginFormForgot {
    float: right;
}

.ant-col-rtl .LoginFormForgot {
    float: left;
}

.LoginFormButton {
    width: 100%;
}