.Toolbar {
    background: #fff;
}

.Trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
}

.Trigger:hover {
    color: #1890ff;
}