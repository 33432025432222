.Teams .TitleContainer {
  padding-top: 5px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eeeeff;
}

.Teams .SubTitle {
  margin: 0 0 10px;
  border-bottom: 1px dashed #eeeeff;
}

.Teams .FormContent {
  padding: 20px 100px;
}

.Teams .Content {
  margin: 20px 0;
}
